.pitag-root {
    color: var(--pi-background);
    text-align: center;
    border-radius: 25px;
    padding: 12px 22px;
    width: 34px;
    height: 22px;
    font-size: 13px;
    font-weight: 500;
    font-family: "Poppins";
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pitag-purple {
    background-color: var(--pi-purple);
}

.pitag-yellow {
    background-color: var(--pi-yellow);
}

.pitag-blue {
    background-color: var(--pi-menu-background);
    color: var(--pi-key-blue);
    border-radius: 5px;
}

.pitag-green {
    background-color: var(--pi-key-green);
    color: white;
    border-radius: 5px;
}
