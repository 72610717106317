.filedropzone-root {
  position: relative;
  /* width: auto;
  height: 250px; */
  width: 90%;
  height: 250px;
  background-color: white;
  border-radius: 10px 10px 10px 10px;
  border: 2px dashed var(--pi-key-blue);
  font-family: "Poppins";
  box-shadow: 0px 8px 12px rgba(16, 24, 40, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.filedropzone-root:hover {
  background-color: var(--pi-main-hover);
}

.filedropzone-content {
  width: 100%;
  height: 80%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filedropzone-content div > * {
  margin-top: 8px;
  margin-bottom: 8px;
}

.filedropzone-icon {
  width: 40px;
  height: 40px;
  background-color: var(--pi-key-blue);
  border-radius: 100%;
  color: white;
  margin: auto;
}

.filedropzone-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

.filedropzone-subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.filedropzone-subtitle span {
  color: var(--pi-key-blue);
  cursor: pointer;
}

.filedropzone-dragactive {
  background-color: var(--pi-key-fade);
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.filedropzone-hide-input {
  display: none;
}
