.actorpresetscolumn-root {
  position: relative;
  width: calc(100% - 32px);
  height: 100%;
}

.actorpresetscolumn-header {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
}

.actorpresetscolumn-addactorbutton {
  margin: 16px 0px 0px 0px;
}

.actorpresetscolumn-addactorbutton-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.actorpresetscolumn-content {
  height: auto;
}
