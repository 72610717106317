.loginform-root {
  display: flex;
  height: 100vh;
}

.loginform-col-left {
  background-color: var(--pits-blue);
  display: flex;
}

.loginform-col-left img {
  height: 324px;
  width: 207px;
  margin: auto 10vw;
}

.loginform-col-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fefefe;
  color: #334754;
  width: 90%;
}

.loginform-form {
  display: inline;
  width: 40vw;
}

.loginform-form-input {
  padding: 1rem 30px;
}

.loginform-copyright {
  text-align: center;
  font-size: 14px;
}

.loginform-subtitle {
  padding: 1rem 60px;
}

.loginform-forgot-password a {
  color: var(--pits-yellow);
  text-decoration: none;
  font-size: 14px;
}

.loginform-loading {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 40vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginform-form-textfield {
  width: 100%;
}
