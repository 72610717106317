.pipopper-menu {
  margin-top: 4px;
  border: 2px solid var(--pi-disabled);
  border-radius: 10px;
  background-color: transparent;
  scrollbar-color: var(--pi-key-blue) var(--pi-scrollbar-bg);
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;
}

.pipopper-menu::-webkit-scrollbar-thumb {
  background-color: var(--pi-key-blue);
  border-radius: 10px;
}
.pipopper-menu::-webkit-scrollbar-track {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
.pipopper-menu::-webkit-scrollbar-corner {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
.pipopper-menu::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.pipopper-menuitem {
  color: var(--pi-text-color-1) !important;
  font-family: "Poppins" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: -0.1px;
  padding: 10px !important;
}
.pipopper-menuitem:hover {
  background-color: var(--pi-main-hover) !important;
}

.Mui-selected {
  background-color: var(--pi-main-hover) !important;
}
