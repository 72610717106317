.orderquizbody-root {
  height: 100%;
  padding-top: 20px;
}

.orderquiz-draggable {
  top: auto !important;
  left: auto !important;
  min-height: 30px;
  padding: 1.155% 0px;
}

.orderquizbody-add-button {
  transform: translate(0, -20%);
}

.orderquizbody-vert {
  display: flex;
  flex-direction: column;
  gap: 5%;
  height: 100%;
  transform: translate(0, -2.3%);
}

.orderquizbody-horz {
  display: flex;
  flex-direction: row;
  gap: 2%;
  height: 100%;
  width: 100%;
  margin-top: 16px;
  position: relative;
}

.orderquiz-draggable-gfx {
  top: auto !important;
  left: auto !important;
}

.orderquiz-droppable-gfx {
  display: flex;
  width: 100%;
}
