.pi-accountpopover-root {
  margin: 8px 0px;
  width: 192px;
}

.pi-accountpopover-content {
  color: black;
  width: calc(100% - 16px);
  padding-left: 8px;
  padding-right: 8px;
}
