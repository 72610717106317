.scrabblequizbody-root {
  display: flex;
  width: 100%;
  height: calc(100% - 64px);
  margin: 32px 0 0 0;
  position: relative;
}

.scrabblequizbody-col1 {
  width: 20%;
  height: 100%;
  border-right: 4px solid var(--pi-game-blue-faded);
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.scrabblequizbody-col2 {
  position: relative;
  width: 80%;
  height: 100%;
}

.scrabblequizbody-add-button {
  margin-right: 16px;
}

.scrabblequizbody-preview {
  width: calc(100% - 32px);
  margin-left: 32px;
  height: 200px;
  background-color: var(--pi-game-blue-faded);
  border-radius: 10px;
  font-size: 48px;
  color: var(--pi-game-blue);
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.scrabblequizbody-preview-question {
  position: absolute;
  top: 16px;
  right: 16px;
}

.scrabblequizbody-preview-replay {
  position: absolute;
  bottom: 0px;
  right: 16px;
}

.scrabblequizbody-preview-word {
  display: flex;
  max-width: 80%;
  height: 100%;
  justify-content: space-evenly;
  gap: 1%;
  flex-grow: 1;
  align-items: center;
  font-size: 32px;
  font-weight: 600;
}

.scrabblequizbody-preview-letter {
  text-decoration: underline;
}

@media screen and (max-width: 1280px) {
  .scrabblequizbody-preview {
    font-size: 28px;
  }
}
