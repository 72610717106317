.trainingactionbar-saved {
  border: 2px solid var(--pi-key-green) !important;
  background-color: var(--pi-key-green-faded) !important;
  color: var(--pi-key-green) !important;
}

.trainingactionbar-saved:hover {
  border: 2px solid var(--pi-key-green) !important;
  background-color: var(--pi-key-green-faded) !important;
  color: var(--pi-key-green) !important;
}

.trainingactionbar-unsaved {
  border: 2px solid var(--pi-key-yellow) !important;
  background-color: var(--pi-key-yellow-faded) !important;
  color: var(--pi-key-yellow) !important;
}

.trainingactionbar-unsaved:hover {
  border: 2px solid var(--pi-key-yellow) !important;
  background-color: var(--pi-key-yellow-faded) !important;
  color: var(--pi-key-yellow) !important;
}
