.coursetranslationcard-root {
  position: relative;
  width: 100%;
  border-radius: 10px;
  border: 2px solid var(--pi-menu-background);
  box-shadow: 0px 8px 12px rgba(16, 24, 40, 0.04);
}
.coursetranslationcard-header {
  cursor: pointer;
  background-color: var(--pi-menu-background);
  border-radius: 5px;
  height: 48px;
  color: var(--pi-text-color-1);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  padding-left: 16px;
  gap: 12px;
  align-items: center;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.coursetranslationcard-flag {
  width: 32px;
  height: auto;
  object-fit: contain;
}

.coursetranslationcard-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.coursetranslationcard-expanded {
  height: 225px;
  margin: 16px 16px;
  transition: height 0.15s;
}

.coursetranslationcard-minimized {
  height: 0px;
  overflow: hidden;
  margin: 0px 16px;
  transition: height 0.15s;
}

.coursetranslationcard-expander {
  position: absolute;
  right: 2px;
}

.coursetranslationcard-up {
  transform: rotate(180deg);
  transition: transform 0.15s;
}

.coursetranslationcard-down {
  transform: rotate(0deg);
  transition: transform 0.15s;
}
