.pi-iconbutton {
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 100%;
  margin: 4px 4px;
  transform: translateY(-8px);
  padding: 4px 4px;
}

.pi-iconbutton:hover {
  background-color: var(--pits-blue);
}
