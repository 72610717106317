.variantfiledropzone-root {
  width: 250px;
  height: 175px;
  border: 2px dashed var(--pi-key-blue);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  cursor: pointer;
}

.variantfiledropzone-root:hover {
  background-color: var(--pi-main-hover);
}

.variantfiledropzone-dragactive {
  background-color: var(--pi-key-fade);
}

.variantfiledropzone-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.10000000149011612px;
  text-align: center;
  color: rgba(95, 109, 126, 1);
}

.variantfiledropzone-text-highlight {
  color: var(--pi-key-blue);
}

.variantfiledropzone-icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--pi-key-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 28px;
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
