.traininglocalizationtable-root {
  position: relative;
  margin: 0px 32px;
}
.traininglocalizationtable-tablehead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: var(--pi-interactionpicker-icon);
}

.traininglocalizationtable-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.traininglocalizationtable-hide-input {
  display: none;
}
.traininglocalization-table-row:hover {
  background-color: var(--pi-main-hover) !important;
}

.traininglocalizationtable-onboarding {
  position: absolute;
  top: -22px;
  right: 32px;
}
