.interactionpointpreview-root {
  position: absolute;
  --image-width: 100%;
  --image-height: 100%;
  --image-x: 0px;
  --image-y: 0px;

  left: var(--image-x);
  top: var(--image-y); 
  width: var(--image-width);
  height: var(--image-height);
  pointer-events: none;
}

.interactionpointpreview-node {
  --marker-x: 50%;
  --marker-y: 50%;
  --marker-background-color: white;
  --marker-border-color: white;
  --marker-font-color: white;

  position: absolute;
  width: 32px;
  height: 32px;
  border: 4px solid var(--marker-border-color);
  background-color: var(--marker-background-color);
  border-radius: 100%;

  left: var(--marker-x);
  top: var(--marker-y);
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--marker-font-color);
  font-size: 32px;

  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.5);

  cursor: pointer;
}

.interactionpointpreview-node-icon {
  width: 36px !important;
  height: 36px !important;
  border-radius: 100% !important;
  
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  
  font-size: 24px;

  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.5);

  cursor: pointer;
  
  box-sizing: border-box;
  transform: translate(-50%, -50%);
}

.interactionpointpreview-node-icon-npc {
  border: 2px solid var(--pi-disabled);
  background-color: white;
  color: var(--pi-text-color-1);
}


.interactionpointpreview-node-player {
  background-color: var(--marker-border-color);
  width: 100%;
  height: 100%;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}



.leaflet-popup-content-wrapper {
  border-radius: 5px !important;
}

.leaflet-popup-content {
  margin: 8px !important;
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
}

.leaflet-popup-content::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

.leaflet-popup-content::-webkit-scrollbar-track {
  background-color: #DFDFDF;
  border-radius: 10px;
}

.leaflet-popup-content::-webkit-scrollbar-thumb {
  background-color: #6C99B3;
  border-radius: 10px;
}

.leaflet-popup-content::-webkit-scrollbar-thumb:hover {
  background-color: #6C99B3;
  border-radius: 10px;
}

.leaflet-popup-tip-container{
  display: none !important;
}