/*=== Tailwind ===*/
@tailwind base;
@tailwind components;
@tailwind utilities;

/*=== Fonts ====*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Inter");

@layer {
    html {
        font-family: "Inter", sans-serif;
    }
}

body {
    font-family: "Poppins", sans-serif !important;
    --pits-blue: #f1f4f5;
    --pi-yellow: rgb(255, 153, 0);
    --pi-yellow-hover: #d78101;
    --pits-menu-border: rgba(0, 0, 0, 0.1);
    --pi-menu-background: #3ac4ff1a;
    --pi-menu-text-color-1: #5f6d7e;
    --pi-menu-text-color-1-faded: #5f6d7e80;
    --pi-key-blue: #3ac4ff;
    --pi-key-blue-hover: #31a5d6;
    --pits-menu-width-expanded: 212px;
    --pits-menu-width-collapsed: 64px;
    --pi-background: #fafbfc;
    --pi-purple: #a55fff;
    --pi-text-color-1: #272d37;
    --pi-link-selected: #437ef7;
    --pi-key-fade: #3ac4ff1a;
    --pi-tooltip-border: #a4a6ab;
    --pi-scrollbar-bg: #747a8b26;
    --pi-video-asset: rgba(37, 132, 186, 1);
    --pi-pdf-asset: rgba(186, 37, 61, 1);
    --pi-audio-asset: var(--pi-game-orange);
    --pi-unsupported-asset: rgba(246, 93, 93, 0.2);
    --pi-error: rgba(246, 93, 93, 1);
    --pi-error-hover: rgb(203, 50, 50);
    --pi-error-faded: rgba(246, 93, 93, 0.2);
    --pi-error-faded-1: rgba(246, 93, 93, 0.1);
    --pi-error-faded-2: rgba(246, 93, 93, 0.5);
    --pi-trainingpreview-bg: rgb(223, 223, 223);
    --pi-timeline-interaction-bg: rgba(246, 246, 246, 0.75);
    --pi-timeline-interaction-selected: rgba(218, 224, 230, 0.2);
    --pi-disabled: rgba(218, 224, 230, 1);
    --pi-interactionpicker-btn-bg: rgba(249, 251, 252, 1);
    --pi-interactionpicker-icon: #5f6d7e;
    --pi-game-blue: #6c99b3;
    --pi-game-orange: #f1691e;
    --pi-game-blue-faded: #f1f5f8;
    --pi-progress-bar: rgba(67, 126, 247, 1);
    --pi-game-green: #8fbc8f;
    --pi-main-hover: rgba(80, 192, 255, 0.21);

    --pi-key-green: rgba(76, 187, 58, 1);
    --pi-key-green-faded: rgba(76, 187, 58, 0.1);
    --pi-key-green-hover: rgba(76, 187, 58, 0.2);

    --pi-key-yellow: rgba(255, 174, 67, 1);
    --pi-key-yellow-faded: rgba(255, 248, 235, 1);

    --pi-wordquiz-hover: rgba(108, 153, 179, 0.2);
    --pi-wordquiz-highlight: rgba(108, 153, 179, 0.4);

    background-color: var(--pi-background);

    --pi-conversation-background: rgba(248, 248, 248, 1);
    height: 100%;
    overflow: hidden;
}

.playit-scrollbar {
    scrollbar-color: var(--pi-key-blue) var(--pi-scrollbar-bg);
    scrollbar-width: thin;
}

.playit-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--pi-key-blue);
    border-radius: 10px;
}

.playit-scrollbar::-webkit-scrollbar-track {
    background-color: var(--pi-scrollbar-bg);
    border-radius: 10px;
}

.playit-scrollbar::-webkit-scrollbar-corner {
    background-color: var(--pi-scrollbar-bg);
    border-radius: 10px;
}

.playit-scrollbar::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}
