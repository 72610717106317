.pibasicquizanswer-root {
  display: flex;
  height: 48px;
  gap: 8px;
}

.pibasicquizanswer-delete-btn {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 48px;
  height: 100%;
  cursor: pointer;
  font-size: 32px;
  color: var(--pi-error);
}
.pibasicquizanswer-delete-btn:hover {
  color: var(--pi-error-faded-2);
}

@media screen and (max-width: 1280px) {
  .pibasicquizanswer-root {
    height: 38px;
  }
}

@media screen and (max-width: 1024px) {
  .pibasicquizanswer-root {
    height: 38px;
  }
}
