.interactionpicker-header {
  font-family: "Poppins";
  padding: 20px;
  font-size: 28px;
  font-weight: 600;
  color: var(--pi-text-color-1);
  display: flex;
  border-bottom: 2px solid var(--pits-menu-border);
}

.interactionpicker-body {
  position: relative;
  margin: 0px 32px;
  height: 100%;
}

.interactionpicker-search {
  height: 64px;
}
.interactionpicker-content {
  height: calc(100% - 72px);
}

.interactionpicker-onboarding {
  position: absolute;
  top: -29px;
  right: 32px;
}
