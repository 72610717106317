.interactionpickerbutton-root {
  width: 175px;
  height: 175px;
  background-color: var(--pi-interactionpicker-btn-bg);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.interactionpickerbutton-default {
  border: solid 2px var(--pi-disabled);
}

.interactionpickerbutton-selected {
  border: solid 2px var(--pi-key-blue);
}

.interactionpickerbutton-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.interactionpickerbutton-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 33px;
  width: 80px;
  height: 80px;
}

.interactionpickerbutton-icon > * {
  color: var(--pi-interactionpicker-icon);
}

.interactionpickerbutton-name {
  width: 100%;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 0px 2px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
