.connectquizanswer-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: calc(100% - 16px);
  margin-right: 32px;
  margin-left: 20px;
  position: relative;
}

.connectquizanswer-root-horz {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  position: relative;
}

.connectquizanswer-root-a {
  justify-content: end;
}

.connectquizanswer-root-b {
  justify-content: start;
}

.connectquizanswer-button {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 3px solid var(--pi-game-blue);
  cursor: pointer;
  z-index: 100;
}

.connectquizanswer-textfield {
  border: none;
  font-size: 22px;
  width: 85%;
  color: var(--pi-game-blue);
  background-color: transparent;
  text-overflow: ellipsis;
  border-bottom: 2px solid var(--pi-game-blue-faded);
  height: 28px;
}

.connectquizanswer-textfield::placeholder {
  color: var(--pi-disabled);
}

.connectquizanswer-textfield:focus {
  outline: none;
  border-bottom: 2px solid var(--pi-game-blue);
}

.connectquizanswer-textfield-a {
  text-align: right;
}

.connectquizanswer-textfield-b {
  text-align: left;
}

.connectquizanswer-selected {
  border-color: var(--pi-game-green) !important;
}

.connectquizanswer-connection {
  pointer-events: none;
  z-index: 100 !important;
}

.connectquizanswer-delete-button {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 48px;
  height: 100%;
  cursor: pointer;
  font-size: 24px;
  color: var(--pi-error);
}
.connectquizanswer-delete-button:hover {
  color: var(--pi-error-faded-2);
}

.connectquizanswer-horizontal-side-a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.connectquizanswer-textfield-horizontal {
  width: 100% !important;
}

.connectquizanswer-horizontal-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .connectquizanswer-textfield {
    height: 32px;
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .connectquizanswer-textfield {
    gap: 6%;
  }
}
