.wordquizsentencesegment-root {
  font-family: "Poppins";
  color: var(--pi-game-blue);
  font-size: 20px;
  cursor: pointer;
}

.wordquizsentencesegment-root:hover {
  background-color: var(--pi-wordquiz-hover);
}

.wordquizsentencesegment-highlight {
  background-color: var(--pi-wordquiz-highlight) !important;
}

.wordquizsentencesegment-correct {
  color: var(--pi-key-green);
  background-color: var(--pi-key-green-hover);
}
.wordquizsentencesegment-correct:hover {
  color: var(--pi-key-green);
  background-color: var(--pi-key-green-hover);
}
