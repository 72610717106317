.pibasicquizbody-root {
  height: 100%;
  padding-top: 20px;
}

.pibasicquizbody-vert {
  display: flex;
  flex-direction: column;
  gap: 5%;
  height: 100%;
}
.pibasicquizbody-horz {
  display: flex;
  flex-direction: row;
  gap: 2%;
  height: 100%;
  margin-top: 16px;
}
.answerContainer {
  display: flex;
  align-items: center;
}

.textField .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--pi-game-blue);
}

.textField:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--pi-game-blue);
}

.textField .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--pi-game-blue);
}

.deleteButton {
  min-width: unset;
}

.deleteButton .MuiSvgIcon-root {
  font-size: 30px;
}

.icon path {
  stroke: white;
  stroke-width: 1;
}

.checkbox .MuiSvgIcon-root {
  font-size: 4rem;
  color: var(--pi-game-blue);
}
.checkbox {
  cursor: default;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
