.piconfirmationdialog-body {
  margin: 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.piconfirmationdialog-header {
  font-size: 32px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.piconfirmationdialog-body span {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.10000000149011612px;
  text-align: center;
}

.piconfirmationdialog-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 85%;
}
.piconfirmationdialog-buttons div {
  flex-grow: 1;
  width: 100%;
}

.piconfirmationdialog-buttons div > * {
  width: 100%;
}

.piconfirmationdialog-header-piBlue {
  color: var(--pi-key-blue);
}
.piconfirmationdialog-header-piRed {
  color: var(--pi-error);
}

.piconfirmationdialog-title {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
}

.piconfirmationdialog-close-button {
  position: absolute;
  top: 12px;
  right: 12px;
}
