.assetcardskeleton-root {
  position: relative;
  width: 90%;
  height: 240px;
  background-color: white;
  border-radius: 10px 10px 10px 10px;
  border: 2px solid var(--pits-menu-border);
  font-family: "Poppins";
  box-shadow: 0px 8px 12px rgba(16, 24, 40, 0.04);
}

.assetcardskeleton-top {
  height: 70%;
  border-radius: 10px 10px 10px 10px;
  width: 100%;
}

.assetcardskeleton-bottom {
  border-top: 2px solid var(--pi-disabled);
}
