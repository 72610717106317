.publishcomplete-root {
  position: relative;
  border: 4px solid var(--pi-key-blue);
  border-radius: 10px;
  width: 473px;
  height: 218px;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25);
}

.publishcomplete-content {
  /* padding: 48px; */
  padding-top: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.publishcomplete-title {
  color: #272d37;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 158.333% */
  letter-spacing: -0.24px;
}

.publishcomplete-subtitle {
  font-size: 12px;
}

.publishcomplete-subtitle-highlight {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.publishcomplete-check-icon {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  background-color: var(--pi-key-blue);
  border: 4px solid white;
  position: absolute;
  top: -42px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 48px;
}

.publishcomplete-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 46px;
  background-color: var(--pi-key-blue);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.publishcomplete-bottom-text {
  font-family: "Poppins";
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-decoration: underline;
  line-height: 64px;
}

.publishcomplete-bottom-icon {
  color: white;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
