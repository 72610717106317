.pi-breadcrumb-element {
  color: var(--pi-menu-text-color-1);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.pi-breadcrumb-element-highlight {
  color: var(--pi-key-blue);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.pi-breadcrumb-element-link {
  color: var(--pi-menu-text-color-1);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}
