.variantdetails-root {
  position: relative;
  width: calc(100% - 32px);
  height: 100%;
  background-color: var(--pi-interactionpicker-btn-bg);
  padding: 0px 16px;
}

.variantdetails-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.10000000149011612px;
  text-align: left;
  padding-top: 12px;
  padding-bottom: 4px;
}

.variantdetails-save-btn {
  position: absolute;
  width: calc(100% - 32px);
  bottom: 16px;
  left: 16px;
}

.variantdetails-edit {
  height: 60%;
  width: 100%;
  padding-top: 16px;
}

.variantdetails-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 50%;
  margin-left: 10%;
}

.variantdetails-loading-content {
  width: 100%;
  margin: auto;
}

.variantdetails-default-dropdown {
  width: calc(100% - 24px);
  border: 2px solid var(--pi-disabled);
  border-radius: 30px;
  height: 40px;
  background-color: white;
  color: var(--pi-disabled);

  display: flex;
  align-items: center;
  padding-left: 24px;
}
