.interactionpickercontent-root {
  display: flex;
  height: calc(90vh - 200px);
}

.interactionpickercontent-accordions {
  width: 60%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-right: 16px;

  scrollbar-color: var(--pi-key-blue) var(--pi-scrollbar-bg);
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;
}

.interactionpickercontent-selector {
  width: 40%;
  background-color: var(--pi-interactionpicker-btn-bg);
}

/* output */
.interactionpickercontent-accordions::-webkit-scrollbar-thumb {
  background-color: var(--pi-key-blue);
  border-radius: 10px;
}
.interactionpickercontent-accordions::-webkit-scrollbar-track {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
.interactionpickercontent-accordions::-webkit-scrollbar-corner {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
.interactionpickercontent-accordions::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
