.messagepopuppreview-root {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-35%, -50%);
  display: flex;
}

.messagepopuppreview-box {
  background-color: white;
  border-radius: 5px;
  width: 60%;
}

.messagepopuppreview-box-body {
  height: 95%;
}

.messagepopuppreview-box-bottom {
  width: 100%;
  height: 22px;
  background-color: var(--pi-game-blue);
  border-radius: 0px 0px 5px 5px;
}

.messagepopuppreview-box-text-area {
  /* height: 70%; */
}

.messagepopuppreview-box-text-area textarea {
  resize: none;
  width: calc(100% - 40px);

  min-height: 100px;
  max-height: 40vh;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 50px;
  color: var(--pi-game-blue);
  margin: 20px;

  border: none;
}

.messagepopuppreview-box-text-area textarea:focus {
  outline: none;
}

.messagepopuppreview-box-body-bottom-button {
  width: 347px;
  height: 63px;
  background-color: var(--pi-game-orange);
  margin: auto;
  border-radius: 10px;
  color: white;
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.messagepopuppreview-arrow {
  margin-top: auto;
  margin-bottom: auto;
  transform: translateX(-1px);
}
.messagepopuppreview-lisa {
  margin-top: auto;
  margin-bottom: auto;
}
