.pi-folderbutton-root {
  display: flex;
  background: none;
  border: none;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 64px;
}

.pi-folderbutton-selected {
  color: var(--pi-key-blue);
  background-color: var(--pi-key-fade);
}

.pi-folderbutton-clickable {
  display: flex;
  width: 85%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.pi-folderbutton-icon {
  margin: 0 16px;
  flex: 0.5;
  line-height: 0px;
}

.pi-folderbutton-text {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 5;
  width: 128px;
}

.pi-folderbutton-menu {
  flex: 1;
  margin-right: 16px;
}

.pi-folderbutton-spacing {
  /* margin: 16px 16px 16px 16px; */

  display: flex;
  background: none;
  border: none;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.pi-folderbutton-selected:hover {
  color: var(--pi-key-blue);
  background-color: var(--pi-key-blue-hover);
}

.pi-folderbutton-spacing:hover {
  cursor: pointer;
  background-color: var(--pits-blue);
}

.pi-folderbutton-rename-input {
  color: var(--pi-key-blue);
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid var(--pi-key-blue);
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  width: 128px;
}
