.environmentmetadataview-root {
  position: absolute;
  --image-width: 100%;
  --image-height: 100%;
  --image-x: 0px;
  --image-y: 0px;

  left: var(--image-x);
  top: var(--image-y);
  width: var(--image-width);
  height: var(--image-height);
}
