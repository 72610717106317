.takecard360header-root {
  display: flex;
  align-items: center;
  padding: 8px 4px 8px 0px;
  font-family: "Poppins";
  cursor: pointer;
}

.takecard360header-root:not(.takecard360header-selected) .takecard360-name {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: var(--pi-text-color-1);
}

.takecard360header-selected {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--pi-key-blue);
}

.takecard360header-root .takecard360-thumbnail {
  min-width: 56px;
  min-height: 43px;
  max-width: 56px;
  max-height: 43px;

  border-radius: 5px;
}

.takecard360header-root .takecard360-thumbnail img {
  object-fit: cover;
  min-width: 56px;
  min-height: 43px;
  max-width: 56px;
  max-height: 43px;
  border-radius: 5px;
}

.takecard360-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 56px;
  min-height: 43px;
  max-width: 56px;
  max-height: 43px;
}

.takecard360-loading {
  display: none;
}

.takecard360-name {
  margin-left: 8px;
  text-align: left;

  width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.takecard360-arrow {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  justify-content: center;
}

.takecard360-delete {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  justify-content: center;
}

.takecard360-icon > * {
  color: var(--pi-text-color-1);
}

.takecard360-up {
  transform: rotate(180deg);
  transition: transform 0.15s;
}

.takecard360-down {
  transform: rotate(0deg);
  transition: transform 0.15s;
}

.takecard360-input {
  background-color: transparent;
  color: var(--pi-key-blue);
  font-family: Poppins;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 0;
}

.takecard360-input:focus {
  outline: none;
}
