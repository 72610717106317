.interactionpickerpreview-root {
  height: 90%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  position: relative;
}

.interactionpickerpreview-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}

.interactionpickerpreview-image-wrapper {
  width: 100%;
  height: 55%;
  background-color: var(--pi-disabled);
  border-radius: 10px;
  margin-top: 16px;
  display: flex;
}
.interactionpickerpreview-image-wrapper img {
  max-width: 80%;
  max-height: 90%;
  margin: auto;
}
.interactionpickerpreview-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--pi-interactionpicker-icon);
  margin-top: 16px;
  height: 22%;
  overflow:scroll;
  scrollbar-color: var(--pi-key-blue) var(--pi-scrollbar-bg);
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;
}

.interactionpickerpreview-add-btn {
  width: 184px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}
