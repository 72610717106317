.trainingpreview-root {
  width: calc(100% - 292px);
  /* background-color: var(--pi-trainingpreview-bg); */
  transition:
    min-width 0.1s,
    width 0.1s;
  overflow: hidden !important;
}

.trainingpreview-root-expanded {
  width: 100%;
}

.trainingpreview-content {
  height: calc(100%);
  /* margin: 16px; */
}
