.piinteractiontitlepreview-root {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.piinteractiontitlepreview-input {
  width: 100%;

  text-overflow: ellipsis;
}
.piinteractiontitlepreview-input input {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: transparent;
  border: none;
  font-family: "Poppins";
  font-size: 24px;
  color: var(--pi-game-blue);
  border-bottom: 2px solid var(--pi-game-blue);
  text-overflow: ellipsis;
}

.piinteractiontitlepreview-root input::placeholder {
  color: var(--pi-disabled);
}

.piinteractiontitlepreview-root input:focus {
  outline: none;
}
