.pibasicgraphicsquizanswerfooter-root {
  width: 100%;
  min-width: 100%;
  height: 48px;
  border-top: 2px solid var(--pi-game-blue);
  display: flex;
}

.pibasicgraphicsquizanswerfooter-checkbox {
  width: 42px;
  min-width: 42px;
  height: 100%;
  border-right: 2px solid var(--pi-game-blue);

  cursor: pointer;
  color: var(--pi-game-blue);
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pibasicgraphicsquizanswerfooter-textfield {
  width: calc(100% - 42px);
  display: flex;
  align-items: center;
  margin: 0px 8px;
}

.pibasicgraphicsquizanswerfooter-textfield input {
  border: none;
  height: 90%;
  width: 100%;

  font-family: "Poppins";
  color: var(--pi-game-blue);
  font-size: 20px;
  text-overflow: ellipsis;
}

.pibasicgraphicsquizanswerfooter-textfield input:focus {
  outline: none;
}
