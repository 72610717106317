.takecard3dcontent-root {
    border-top: 2px solid var(--pits-menu-border);
    box-sizing: border-box;
    overflow: hidden;
}

.takecard3dcontent-expanded {
    /* transition: display 0.2s ease-in-out; */
    height: auto;
}

.takecard3dcontent-collapsed {
    /* display: none;
    transition: display 0.2s ease-in-out; */
    height: 0px;
    margin-top: 0px;
    border: none;
}

.take3dcardcontent-addinteraction {
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0px 0px;
    display: flex;
    justify-content: center;
}

.take3dcardcontent-addinteraction-border {
    border-top: 2px solid var(--pits-menu-border);
}

.take3dcardcontent-addinteraction-button {
    color: white;
    background-color: var(--pi-key-blue);
    border-radius: 100%;
    font-family: "Poppins";
}

.takecard3dcontent-title {
    padding-left: 16px;
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.1px;

    border-bottom: 2px solid var(--pits-menu-border);
    background-color: white;
}

.takecard3dcontent-involvedactor-grid {
    padding: 8px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 32px);
    grid-gap: 10px;

    /* border-top: 2px solid var(--pits-menu-border); */
    border-bottom: 2px solid var(--pits-menu-border);
    background-color: var(--pi-timeline-interaction-bg);
}

.takecard3dcontent-involvedactor img {
    object-fit: contain;
    width: 32px;
    height: 32px;

    border-radius: 100%;
    border: 2px solid var(--pits-menu-border);
}

.takecard3dcontent-interactions {
    /* border-top: 2px solid var(--pits-menu-border);
    border-bottom: 2px solid var(--pits-menu-border); */

    background-color: var(--pi-timeline-interaction-bg) !important;
    padding: 0px 0px;
    min-height: 1px;
    margin: 0px;
}

.takecard3dcontent-involvedactor-noactors {
    padding: 8px;
    /* border-top: 2px solid var(--pits-menu-border); */
    border-bottom: 2px solid var(--pits-menu-border);
    background-color: var(--pi-timeline-interaction-bg);
    color: black;
    font-style: italic;
}

.takecard3dcontent-involvedactor-player {
    width: 32px;
    height: 32px;

    border-radius: 100%;
    background-color: var(--pi-purple);
    color: white;
    font-size: 24px;
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;
}

.takecard3dcontent-folded-droppable {
    height: 1px;
}

.takecard3dcontent-main-camera-wrapper {
    background-color: var(--pi-timeline-interaction-bg);
    padding-bottom: 2px;
}

.takecard3dcontent-default-camera {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 8px;
    height: 20px;
    font-size: 20px;
    color: var(--pi-disabled);
}

.take3dcontent-default-camera-text {
    margin-left: 8px;
    width: 170px;
    font-family: "Poppins";
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
