.interactionpickeraccordion-root {
  margin-bottom: 16px;
  border-bottom: 2px solid var(--pi-disabled);
}
.interactionpickeraccordion-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.interactionpickeraccordion-pickers {
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);

  column-gap: 32px;
  row-gap: 32px;
}
