.pi-switchorganization-list {
  display: block;
  margin-top: 12px;
}

.pi-switchorganization-close {
  right: 8px;
  top: 8px;
  position: absolute !important;
}

.pi-switchorganization-content {
  margin-top: 8px;
  height: 100%;
}

.pi-switchorganization-textfield {
  width: 100%;
}
