.picardcontent-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  padding: 5px;
}

.picardcontent-title-blue {
  color: var(--pi-key-blue);
}

.picardcontent-title-purple {
  color: var(--pi-purple);
}

.picardcontent-footer {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 68px;
  border-top: 2px solid var(--pits-menu-border);
  display: flex;
  justify-content: center;
  align-items: center;
}

.picardcontent-footer-button {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.picardcontent-body {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.picardcontent-body > * {
  margin-top: 16px;
}
