.piprogressbar-root {
  width: 100%;
  height: 100%;
  background-color: var(--pi-menu-background);
  border-radius: 5px;
  color: transparent;
  display: flex;
}

.piprogressbar-blue {
  height: 8px;
  background-color: var(--pi-key-blue);
  border-radius: 5px 0px 0px 5px;
}
.piprogressbar-yellow {
  height: 8px;
  background-color: var(--pi-yellow);
  border-radius: 0px 5px 5px 0px;
}
