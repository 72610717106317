.clickregion-root {
  position: absolute;
  --image-width: 100%;
  --image-height: 100%;
  --image-x: 0px;
  --image-y: 0px;

  left: var(--image-x);
  top: var(--image-y);
  width: var(--image-width);
  height: var(--image-height);
  pointer-events: none;
}

.clickregion-marker {
  position: absolute;
  --marker-x: 50%;
  --marker-y: 50%;
  --marker-size-x: 10%;
  --marker-size-y: 10%;

  width: var(--marker-size-x);
  height: var(--marker-size-y);

  left: var(--marker-x);
  top: var(--marker-y);

  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 32px;
  transform: translate(-32px, -32px);

  border: 4px solid var(--pi-key-blue);
  border-radius: 10px;
  transform: translate(-50%, -50%);

  pointer-events: all;
}

.clickregion-resize-dot {
  width: 24px;
  height: 24px;
  background-color: var(--pi-key-blue);
  position: absolute;
  bottom: -12px;
  right: -12px;
  border-radius: 100%;
  z-index: 10;
  pointer-events: all;
}

.clickregion-highlight {
  z-index: 100;
  pointer-events: all;
}

.clickregion-resize-dot:hover {
  cursor: se-resize;
}

.clickregion-dragging {
  cursor: grabbing;
}

.clickregion-root.draggable {
  background-color: red;
}

.clickregion-index-display {
  color: var(--pi-key-blue);
}

.clickregion-delete-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0px;
  right: 8px;
  z-index: 10;
  color: var(--pi-error);
  font-size: 32px;
  cursor: pointer;
}

.clickregion-delete-button:hover {
  color: var(--pi-error-faded-2);
}

.clickregionpreview-checkbox {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: -48px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  color: var(--pi-error);
  font-size: 32px;
  cursor: pointer;
}
