.projectstable-root {
  border: 2px solid var(--pits-menu-border);
  border-radius: 10px;
}

.projectstable-searchbar {
  display: flex;
  height: 72px;
  border-bottom: 1px solid var(--pits-menu-border);
}

.projectstable-searchbar-label {
  width: 107px;
  text-align: center;
  margin: 24px;
}

.projectstable-searchbar-inputfield {
  width: 100%;

  margin-top: 12px;
  margin-right: 24px;
}

.projectstable-tablecell {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-family: "Poppins";
  color: var(--pi-text-color-1);
}

.projectstable-pagination {
  display: flex;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

.projectstable-pagination-prev {
  padding-left: 10px;
}

.projectstable-pagination-next {
  text-align: right;
  padding-right: 10px;
}

.projectstable-pagination-pages {
  flex: 5 5 auto;
  text-align: center;
  justify-content: center;
}

.projectstable-pagination-pages-content {
  display: flex;
  gap: 32px;
  justify-content: center;
  margin: auto;
  font-weight: 500;
  color: var(--pi-menu-text-color-1);
  font-size: 14px;
  line-height: 20px;
  padding-top: 4px;
  font-family: "Poppins";
}

.projectstable-pagination-page-selected {
  color: var(--pi-link-selected);
}
.projectstable-row {
  cursor: pointer;
}
.projectstable-row:hover {
  background-color: var(--pi-main-hover) !important;
}
