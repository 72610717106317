.piordergraphicsquizanswerfooter-root {
  position: relative;
  height: 100%;
  width: 100%;
  border: 2px solid var(--pi-game-blue);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.piordergraphicsquizanswerfooter-select-image {
  position: relative;
  top: -16px;
  width: 100%;
  height: 70%;
  min-width: 20%;
  background-color: var(--pi-game-blue-faded);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: var(--pi-game-blue);
  margin: 0px 8px;
  cursor: pointer;
}

.piordergraphicsquizanswerfooter-image-display {
  position: relative;
  top: -16px;
  width: 100%;
  height: 70%;
  min-width: 20%;
  background-color: var(--pi-game-blue-faded);
  border-radius: 10px;

  margin: 0px 8px;
}

.piordergraphicsquizanswerfooter-image-display img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.piordergraphicsquizanswerfooter-select-image:hover {
  opacity: 0.75;
}

.piordergraphicsquizanswerfooter-change-image {
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: white;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--pi-game-blue);
  border-radius: 100%;
  padding: 8px;
}

.piordergraphicsquizanswerfooter-change-image:hover {
  opacity: 0.75;
}

.piordergraphicsquizanswerfooter-close {
  position: absolute;
  top: 8px;
  right: 8px;
  color: var(--pi-error);
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
}

.piordergraphicsquizanswerfooter-close:hover {
  color: var(--pi-error-faded-2);
}

.piordergraphicsquizanswerfooter-index {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 48px;
  min-width: 48px;
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 500;
  color: var(--pi-game-blue);
  border-right: 2px solid var(--pi-game-blue);
}

.piordergraphicsquizanswerfooter-textfield input {
  height: 100%;
  width: calc(100% - 8px);
  background-color: transparent;
  border: none;
  font-family: "Poppins";
  font-size: 24px;
  color: var(--pi-game-blue);
  margin-left: 4px;
  text-overflow: ellipsis;
}

.piordergraphicsquizanswerfooter-textfield input::placeholder {
  color: var(--pi-disabled);
}

.piordergraphicsquizanswerfooter-textfield input:focus {
  outline: none;
}

.piordergraphicsquizanswerfooter-footer {
  position: absolute;
  height: 48px;
  bottom: 0;
  left: 0;
  border-top: 2px solid var(--pi-game-blue);
  width: 100%;
  display: flex;
}


.piordergraphicsquizanswerfooter-checkbox {
  position: absolute;
  bottom: 4px;
  height: 40px;
  transform: scale(0.75);
}

.piordergraphicsquizanswerfooter-change-image {
  font-size: 24px;
}

.piordergraphicsquizanswerfooter-image-display {
  top: -20px;
}

@media screen and (max-width: 1024px) {
  .piordergraphicsquizanswerfooter-checkbox {
    position: absolute;
    bottom: 4px;
    height: 40px;

    transform: scale(0.75);
  }
}