.pi-folderview-content-folders {
  flex: 1;
  max-width: 248px;
  min-width: 248px;
  max-height: 98%;
}

.pi-folderview-content-folders-full-height {
  height: 95%;
}

.pi-folderview-content-folders-add {
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 32px;
}
.pi-folderview-content-folders-add-button {
  display: flex;
}

.pi-folderview-content-folders-add-button > * {
  margin-right: 10px;
}

.pi-folderview-content-folders-list {
  scrollbar-color: var(--pi-key-blue) var(--pi-scrollbar-bg);
  scrollbar-width: thin;
  overflow-y: scroll !important;
}

/* output */
.pi-folderview-content-folders-list::-webkit-scrollbar-thumb {
  background-color: var(--pi-key-blue);
  border-radius: 10px;
}
.pi-folderview-content-folders-list::-webkit-scrollbar-track {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
.pi-folderview-content-folders-list::-webkit-scrollbar-corner {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
.pi-folderview-content-folders-list::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
