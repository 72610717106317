.newtrainingcard-root {
  position: relative;
  height: 529px;
  width: 450px;
  background-color: white;
  border-radius: 10px;
  border: 2px solid var(--pits-menu-border);
}

.newtrainingcard-tag {
  color: white;
  position: absolute;
  left: 15px;
  top: 15px;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  padding: 2px 8px;
  border-radius: 5px;
}

.newtrainingcard-tag-purple {
  background-color: var(--pi-purple);
}

.newtrainingcard-tag-yellow {
  background-color: var(--pi-yellow);
}

.newtrainingcard-image {
  position: relative;
  border-radius: 10px 10px 0px 0px;
  height: 70%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.newtrainingcard-image img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.newtrainingcard-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.newtrainingcard-title-purple {
  color: var(--pi-purple);
}

.newtrainingcard-title-yellow {
  color: var(--pi-yellow);
}

.newtrainingcard-button {
  border-top: 2px solid var(--pits-menu-border);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15%;
  padding: 0px 15px;
}

.newtrainingcard-coming-soon {
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.newtrainingcard-coming-soon-text {
  color: white;
  font-family: "Poppins";
  font-size: 48px;
  z-index: 10;
  font-weight: 500;

  transform: rotate(35deg);
}

.newtrainingcard-coming-soon-overlay {
  background-color: var(--pi-disabled);
  opacity: 0.65;
  position: absolute;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1280px) {
  .newtrainingcard-root {
    height: 410px;
    width: 400px;
  }
  .newtrainingcard-coming-soon-text {
    font-size: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .newtrainingcard-root {
    height: 250px;
    width: 300px;
  }
}
