.conversationdetailsview-root {
  width: 100%;
  height: 100%;

  border-radius: 10px;
  background-color: var(--pi-conversation-background);

  padding: 16px;
}

.conversationdetailsview-title {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 22px;
  font-weight: 500;
  color: var(--pi-text-color-1);
}

.conversationdetailsview-prop {
  margin: 12px;
  margin-top: 16px;
  width: calc(100% - 48px);
}

.conversationdetailsview-prop-header {
  font-weight: 500;
  font-size: 16px;
  font-family: Poppins;
  color: var(--pi-text-color-1);
}

.conversationdetailsview-prop-textarea {
  width: 100%;
  background-color: white;
  border: none;
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  padding: 16px;
}

.conversationdetailsview-prop-textarea textarea {
  border: none;
  width: 100%;
  resize: none;
  color: var(--pi-game-blue);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.conversationdetailsview-prop-textarea textarea:focus {
  outline: none;
}

.conversationdetailsview-prop-npcs {
  width: 100%;
  background-color: white;
  border: none;
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  padding: 16px;
  height: 48px;
}
