.trainingbubbleviewport-root {
  width: 100%;
  height: calc(100% - 78px);
  position: relative;
}

.trainingbubbleviewport-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: black;
  border-radius: 10px 10px 0px 0px;
}
