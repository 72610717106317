.piselectassetbutton-root {
}

.piselectassetbutton-label {
  color: var(--pi-text-color-1);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
}

.piselectassetbutton-button {
  margin-top: 8px;
  height: 38px;
}
