.pidialog-close-button {
  right: 29.5px;
  top: 37.5px;
  position: absolute !important;
}

.pidialog-close-button:hover {
  background-color: var(--pi-main-hover) !important;
}

.pidialog-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}
