.trainingactionbartitle-root {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  max-width: 235px !important;
  width: 235px !important;
  color: var(--pi-text-color-1);
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.trainingactionbartitle-input {
  border: none;
  font-family: Poppins;
  color: var(--pi-text-color-1);
  max-width: 235px !important;
  width: 235px !important;
  background-color: transparent;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.trainingactionbartitle-input:focus {
  outline: none;
  border-bottom: solid 2px var(--pi-key-blue)
}

.trainingactionbartitle-input:not(:focus):hover {
  border-bottom: solid 2px var(--pi-disabled);
}

