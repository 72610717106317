.actorstatepropertiescolumn-root {
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: calc(100% - 32px);
}

.actorstatuspropertiescolumn-actor-status-label {
  display: flex;

  align-items: center;
  gap: 8px;
  color: var(--pi-trainingpreview-bg);
  font-size: 14px;
}
