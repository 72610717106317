.editbubble-dialog-body {
  margin: 8px 36px;
}

.editbubble-dialog-body > * {
  margin-bottom: 16px;
}

.editbubble-dialog-switches {
  margin-top: 30px;
}
.editbubble-dialog-switches > * {
  margin-bottom: 8px;
}
