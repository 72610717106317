.propertiescolumn-root {
  position: relative;
  width: 292px;
  min-width: 292px;

  height: 100%;
  background-color: white;
  border-right: 2px solid var(--pits-menu-border);
  scrollbar-color: var(--pi-key-blue) var(--pi-scrollbar-bg);
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;
  transition: min-width 0.1s, width 0.1s;

  overflow-y: hidden;
}

.propertiescolumn-root-closed {
  width: 0px;
  min-width: 0px;
  transition: width 0.1s;
}
