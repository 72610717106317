.dashboardheader-root {
  margin-left: 32px;
}

.dashboardheader-greeting {
  margin-top: 20px;
}

.dashboardheader-greeting-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
}

.dashboardheader-greeting-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  color: var(--pi-menu-text-color-1);
}

.dashboardheader-body {
  margin-top: 20px;
}

.dashboardheader-children {
  width: 200px;
  float: right;
  margin-right: 32px;
}
