.pimenu-button-root {
  text-align: left;
  height: 50px;
  margin: 2px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 50px;

  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  border-radius: 5px;
  width: 100%;

  overflow-x: hidden;
  overflow-y: hidden;
}

.pimenu-button-hightlight {
  background-color: var(--pi-menu-background);
  color: var(--pi-key-blue);
}

.pimenu-button-disabled {
  background-color: white;
  color: var(--pi-disabled);
  pointer-events: none;
}

.pimenu-button-root:hover {
  background-color: var(--pi-main-hover);
}

.pimenu-button-icon-wrapper {
  margin-right: 16px;
  margin-left: 4px;
  line-height: 65px;
}

.pimenu-button-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pimenu-button-label {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  margin-left: 8px;
}

.pimenu-button-label-content {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 24px;
  width: 28px;

  border-radius: 7px;
  background-color: var(--pi-yellow);
  color: white;
  font-size: 12px;
}
