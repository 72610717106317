.scrabblequiztextfield-root {
  width: calc(100% - 16px);
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-right: 16px;
}

.scrabblequiztextfield-input {
  border: none;
  border-bottom: 2px solid var(--pi-game-blue-faded);
  font-family: "Poppins";
  font-size: 24px;
  width: 100%;
  color: var(--pi-game-blue);
  text-overflow: ellipsis;
}

.scrabblequiztextfield-input:focus {
  outline: none;
  border-bottom: 2px solid var(--pi-game-blue);
}

.scrabblequiztextfield-input::placeholder {
  color: var(--pi-disabled);
}

.scrabblequiztextfield-delete-button {
  font-size: 28px;
  color: var(--pi-error);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scrabblequiztextfield-delete-button:hover {
  color: var(--pi-error-faded-2);
}

.scrabblequiztextfield-bold {
  font-weight: 600;
}

.scrabblequiztextfield-error {
  color: var(--pi-error);
}

@media screen and (max-width: 1280px) {
  .scrabblequiztextfield-root {
    height: 24px;
  }

  .scrabblequiztextfield-input {
    font-size: 16px;
  }
}
