.photorangequizbody-root {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.photorangequizbody-pencil {
  font-size: 24px;
  color: white;
  background-color: var(--pi-game-blue);
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  left: 50%;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  transform: translate(-50%, 0);
}

.photorangequizbody-pencil:hover {
  opacity: 0.75;
}

.photorangequizbody-basic-view {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--pi-game-blue-faded);
  border-radius: 10px;
}

.photorangequizeditview-root {
  width: 100%;
  height: 95%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.photorangequizeditview-select-base-image {
  position: relative;
  height: 80%;
  margin: 10% 16px;
  width: 25%;
  border: 2px solid var(--pi-game-blue);
  border-radius: 10px;
  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;
}

.photorangequizeditview-image-preview {
  width: 90%;
  height: 90%;
  background-color: var(--pi-game-blue-faded);
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--pi-game-blue);
  cursor: pointer;
}

.photorangequizeditview-image-preview:hover {
  opacity: 0.75;
}

.photorangequizeditview-answers-preview {
  width: 75%;
  height: 80%;
  margin: 10% 0;

  display: flex;
  gap: 16px;
  justify-content: center;
  justify-content: center;
}

.photorangequizeditview-image-display {
  position: relative;
  background-color: var(--pi-game-blue-faded);
  border-radius: 10px;
  width: 90%;
  height: 90%;
}

.photorangequizeditview-change-image {
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: white;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--pi-game-blue);
  border-radius: 100%;
  padding: 8px;
}
.photorangequizeditview-image-display img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.photorangequizbody-overlay-image {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--pi-game-blue-faded);
  border-radius: 10px;
}

.photorangequizbody-overlay-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.photorangequizbody-overlay-image-answer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1280px) {
  .photorangequizbody-pencil {
    bottom: -12px;
  }
}
