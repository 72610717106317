.viewport3d-root {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  border-radius: 20px;
}

/*.viewport3d-root img {*/
/*  object-fit: contain;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  border-radius: 20px;*/
/*}*/
