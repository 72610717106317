.projectstablepagination-root {
  width: 100%;
}

.projectstablepagination-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 100px auto 100px;
  height: 40px;
  align-items: center;
  position: relative;
}
.projectstablepagination-pages {
  display: flex;
  justify-content: center;
}

.projectstablepagination-page {
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.1px;
  width: 40px;
  color: var(--pi-interactionpicker-icon);
}

.projectstablepagination-selected {
  color: var(--pi-progress-bar);
}

.projectstablepagination-page:hover {
  cursor: pointer;
}

.projectstablepagination-next {
  position: absolute;
  right: 0;
}