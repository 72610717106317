.pibasicgraphicsquizanswer-root {
  position: relative;
  height: 100%;
  width: 100%;
  border: 2px solid var(--pi-game-blue);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pibasicgraphcisquizanswer-select-image {
  position: relative;
  top: 0px;
  width: 100%;
  height: 80%;
  min-width: 20%;
  background-color: var(--pi-game-blue-faded);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: var(--pi-game-blue);
  margin: 0px 16px;
  cursor: pointer;
  top: -6%;
}

.pibasicgraphcisquizanswer-image-display {
  position: relative;
  width: 100%;
  height: 80%;
  min-width: 20%;
  background-color: var(--pi-game-blue-faded);
  border-radius: 10px;
  top: -6%;
  margin: 0px 16px;
}

.pibasicgraphcisquizanswer-image-display img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.pibasicgraphcisquizanswer-select-image:hover {
  opacity: 0.75;
}

.pibasicgraphcisquizanswer-change-image {
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: white;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--pi-game-blue);
  border-radius: 100%;
  padding: 8px;
}

.pibasicgraphcisquizanswer-change-image:hover {
  opacity: 0.75;
}

.pibasicgraphicsquizanswer-close {
  position: absolute;
  top: 8px;
  right: 8px;
  color: var(--pi-error);
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
}

.pibasicgraphicsquizanswer-close:hover {
  color: var(--pi-error-faded-2);
}

.pibasicgraphicsquizanswer-checkbox {
  height: 48px;
  min-height: 48px;
  width: 10%;
  transform: translate(-50%, 0);
}

.pibasicgraphicsquizanswer-textfield {
  height: 48px;
  width: calc(85% - 14px);
  transform: translate(-8px, 0);
}

.pibasicgraphicsquizanswer-bottom {
  position: absolute;
  bottom: 16px;
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.pibasicgraphicsquizanswer-bottom-v2 {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

@media screen and (max-width: 1280px) {
  /* .pibasicgraphicsquizanswer-checkbox {
    position: absolute;
    bottom: 4px;
    height: 40px;
    transform: scale(0.75);
  } */

  .pibasicgraphicsquizanswer-textfield {
    height: 32px;
  }
  .pibasicgraphicsquizanswer-checkbox {
    transform: translate(-100%, 0);
  }
  .pibasicgraphcisquizanswer-change-image {
    font-size: 24px;
  }

  .pibasicgraphcisquizanswer-image-display {
    height: 65%;
    top: -5%;
  }

  .pibasicgraphicsquizanswer-bottom {
    bottom: 32px;
  }

  .pibasicgraphcisquizanswer-select-image {
    height: 65%;
    top: -5%;
  }
}

@media screen and (max-width: 1024px) {
  .pibasicgraphicsquizanswer-checkbox {
    position: absolute;
    bottom: 4px;
    height: 40px;

    transform: scale(0.75);
  }
}
