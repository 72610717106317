.wordspinnerquizbody-root {
  height: calc(100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 32px;
}

.wordspinnerquizbody-background {
  height: 45%;
  width: 100%;
  background-color: var(--pi-game-blue-faded);
  border-radius: 5px;
  padding-top: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wordspinnerquizbody-sentenceview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.wordspinnerquizbody-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  color: var(--pi-game-blue);
}

.wordspinnerquizbody-all-answers {
  width: calc(100% - 50px);
  height: 30%;
  display: flex;
  justify-content: center;
}

.dropzone{
  display: flex;
  flex:1;
}

.wordspinnerquiz-edit-button {
  font-size: 22px !important;
  color: var(--pi-game-blue) !important;
  transform: translate(0, -3px);
}

.wordspinnerquiz-finished-button {
  font-size: 22px !important;
  color: var(--pi-game-blue) !important;
  transform: translate(5px, -3px);
}

.wordspinnerquiz-placeholder {
  color: rgba(0, 0, 0, 0.2);
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
}

.wordspinnerquiz-edit-menu {  
   position: absolute;  
   transform: translate(-50%, -8px);  
 }  

 .hidden {  
  display: none;  
}  

@media screen and (max-width: 1280px) {
  .wordspinnerquizbody-root {
    height: calc(100%);
    row-gap: 16px;
  }
}