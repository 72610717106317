.variantcard-root {
  border: 2px solid rgba(223, 223, 223, 1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  width: 250px;
  height: 175px;
  border-radius: 10px;
  cursor: pointer;
}

.variantcard-root:hover {
  border: 2px solid var(--pi-key-blue);
}

.variantcard-selected {
  border: 2px solid var(--pi-key-blue);
}

.variantcard-preview {
  height: 119px;
}

.variantcard-info {
  border-top: 2px solid rgba(223, 223, 223, 1);
  height: 56px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.variantcard-info-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.10000000149011612px;
  text-align: left;
  margin-left: 16px;
  height: 32px;
}

.variantcard-preview-image img {
  width: 100%;
  height: 119px;
  object-fit: contain;
  border-radius: 10px 10px 0px 0px;
}

.variantcard-preview-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.variantcard-preview-icon img {
  width: 48px;
}

.variantcard-loading {
  cursor: not-allowed !important;
}

.variantcard-loading:hover {
  border: 2px solid var(--pi-disabled) !important;
}

.variantcard-loading-icon {
  stroke: var(--pi-interactionpicker-icon);
  stroke-width: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.variantcard-progress {
  width: calc(100% - 32px);
  padding: 0px 16px;
}
