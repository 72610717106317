.pidragbutton-root {
  position: relative;
  height: 55px;
  width: 85%;
  display: flex;
  margin-left: auto; /* Add this line */
  margin-right: auto; /* Add this line */
}

.pidragbutton {
  flex: 1;
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--pi-wordquiz-highlight);
  font-family: "Poppins";
  font-size: 24px;
  color: var(--pi-game-blue);
  overflow: visible;
  background-color: var(--pi-game-blue-faded);
  box-sizing: border-box;
  padding-left: 46px;
  padding-right: 12px;
}

.pidragbutton-text-correct {
  color: var(--pi-key-green);
  font-weight: 500;
  font-size: 20px;
  display: flex;
}

.pidragbutton-text-wrong {
  color: var(--pi-error);
  font-weight: 500;
  font-size: 20px;
  display: flex;
}

.pidragbutton-error {
  color: var(--pi-error);
}

.pidragbutton::placeholder {
  color: var(--pi-disabled);
}

.pidragbutton:focus {
  outline: none;
  border-bottom: 2px solid var(--pi-game-blue);
}

.pidragbutton-delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--pi-error);
}

.pidragbutton-drag-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  color: var(--pi-game-blue);
  border: none;
  background-color: transparent;
}

.pidragbutton-drag-icon:hover {
  cursor: grab;
}

.pidragbutton-toggle {
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translate(-50%, 0);
}

@media screen and (max-width: 1280px) {
  .pidragbutton-root {
    position: relative;
    height: 35px;
    width: 85%;
    display: flex;
    background-color: darkblue;
  }
}