.pi-fileexplorer-close {
  right: 29.5px;
  top: 37.5px;
  position: absolute !important;
}

.pi-fileexplorer-header {
  padding: 20px;
  font-size: 28px;
  font-weight: 600;
  color: var(--pi-text-color-1);
  display: flex;
  border-bottom: 2px solid var(--pits-menu-border);
}

.pi-fileexplorer-header-filter {
  margin-left: 8px;
  color: var(--pi-key-blue);
  font-weight: 400;
}

.pi-fileexplorer-content {
  display: flex;
  height: calc(90vh - 250px);
  margin-top: 32px;
}

.pi-fileexplorer-content-folders {
  flex: 1;
  max-width: 248px;
  min-width: 156px;
}

.pi-fileexplorer-content-folders-add {
  margin-top: 16px;
}
.pi-fileexplorer-content-folders-add-button {
  display: flex;
}

.pi-fileexplorer-content-folders-add-button > * {
  margin-right: 10px;
}

.pi-fileexplorer-content-files {
  flex: 5;
}

.pi-fileexplorer-content-folders-full-height {
  height: 100%;
}

.pi-fileexplorer-content-folders-list {
  scrollbar-color: var(--pi-key-blue) var(--pi-scrollbar-bg);
  scrollbar-width: thin;
}

/* output */
.pi-fileexplorer-content-folders-list::-webkit-scrollbar-thumb {
  background-color: var(--pi-key-blue);
  border-radius: 10px;
}
.pi-fileexplorer-content-folders-list::-webkit-scrollbar-track {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
.pi-fileexplorer-content-folders-list::-webkit-scrollbar-corner {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
.pi-fileexplorer-content-folders-list::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.pi-fileexplorer-body {
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
}

.pi-fileexplorer-content-temporary {
  display: flex;
  height: calc(90vh - 200px);
  margin-top: 32px;
}

.pi-fileexplorer-onboarding {
  position: absolute;
  top: -31px;
  right: 70px;
}
