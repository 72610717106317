.assetview-root {
  flex: 5;
  margin-left: 32px;
}

.assetview-scroller {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-x: hidden;
}

.assetview-grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 340px);
  column-gap: 32px;
  row-gap: 32px;
  justify-content: center;
  overflow: scroll;
  scrollbar-color: var(--pi-key-blue) var(--pi-scrollbar-bg);
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;
}

.assetview-grid-container {
}

/* output */
.assetview-grid-container::-webkit-scrollbar-thumb {
  background-color: var(--pi-key-blue);
  border-radius: 10px;
}
.assetview-grid-container::-webkit-scrollbar-track {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
.assetview-grid-container::-webkit-scrollbar-corner {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
.assetview-grid-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.assetview-virtualized-cell {
  margin: 8px;
}

.ReactVirtualized__Grid::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.ReactVirtualized__Grid::-webkit-scrollbar-track {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}

.ReactVirtualized__Grid::-webkit-scrollbar-thumb {
  background-color: var(--pi-key-blue);
  border-radius: 10px;
}

.ReactVirtualized__Grid::-webkit-scrollbar-corner {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
