.variantdetailsedit-root {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;

  border-radius: 20px;
  border: 2px solid var(--pi-key-blue);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  cursor: pointer;
}

.variantdetailsedit-root:hover {
  background-color: var(--pi-main-hover);
}

.variantdetailsedit-preview {
  height: 70%;
  width: 80%;
}

.variantdetailsedit-preview img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.variantdetailsedit-preview-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.variantdetailsedit-preview-icon img {
  height: 60%;
  width: 60%;
}
.variantdetailsedit-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.10000000149011612px;
  text-align: center;
  color: rgba(95, 109, 126, 1);
}

.variantdetailsedit-text-highlight {
  color: var(--pi-key-blue);
}

.variantdetailsedit-edit-btn {
  position: absolute;
  top: 16px;
  left: 16px;
}

.variantdetailsedit-dragactive {
  background-color: var(--pi-main-hover);
}
