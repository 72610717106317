.variantslist-root {
  width: 100%;
  height: 100%;
}

.variantslist-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.10000000149011612px;
  text-align: left;
  margin-top: 12px;
}

.variantslist-search {
  width: 95%;
}

.variantslist-grid-wrapper {
  display: flex;
  height: calc(100% - 112px);
  padding-top: 32px;
}

.variantslist-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 275px);
  grid-template-rows: repeat(auto-fit, 175px);
  grid-gap: 20px 10px;
  height: calc(100%);
  align-items: flex-start;
  overflow-y: auto;
}
