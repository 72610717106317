.take360cardcontent-root {
  overflow: hidden;
  margin-top: 8px;
  border-top: 2px solid var(--pits-menu-border);
  background-color: var(--pi-timeline-interaction-bg);
}

.take360cardcontent-expanded {
  height: auto;
}

.take360cardcontent-folded {
  height: 0px;
  margin-top: 0px;
  border: none;
}

.take360cardcontent-addinteraction-border {
  border-top: 2px solid var(--pits-menu-border);
}

.take360cardcontent-addinteraction {
  border-bottom: 2px solid var(--pits-menu-border);
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0px 0px;
  display: flex;
  justify-content: center;
}

.take360cardcontent-addinteraction-button {
  color: white;
  background-color: var(--pi-key-blue);
  border-radius: 100%;
  font-family: "Poppins";
}

.take360cardcontent-addinteraction-button > * {
  font-size: 32px;
}

.take360cardcontent-interactions {
  min-height: 1px;
}
