.pinumberproperty-root {
  display: flex;
  width: 100%;
  height: 38px;
  align-items: center;
}

.pinumberproperty-label {
  width: calc(100% - 48px);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.pinumberproperty-input {
  width: 32px;
}

.pinumberproperty-input input {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 30px;
  border: none;
  border-bottom: 2px solid var(--pi-disabled);
  text-align: right;
}

.pinumberproperty-input input:focus {
  border: none;
  border-bottom: 2px solid var(--pi-key-blue);
  outline: none;
  font-weight: 500;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
