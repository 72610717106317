.ambientaudiopreview-root {
  position: relative;
  width: 100%;
  height: 100%;
}

.ambientaudiopreview-title {
  color: var(--pi-game-blue);
  font-size: 14px;
  font-size: 24px;
  font-weight: 500;
}

.ambientaudiopreview-add-button {
  border-radius: 100%;
  width: 48px;
  height: 48px;
  font-size: 24px;
  background-color: var(--pi-game-blue);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 0%);
  cursor: pointer;
}

.ambientaudiopreview-content {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: var(--pi-audio-asset);

  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.ambientaudiopreview-add-button:hover {
  opacity: 0.75;
}

.ambientaudiopreview-asset-name {
  vertical-align: middle;
}

.ambientaudiopreview-audio {
  stroke: var(--pi-audio-asset);
  stroke-width: 2;
  width: 32px;
  height: 32px;
}
