.pi-editassetdialog-header {
  padding: 20px;
  font-size: 28px;
  font-weight: 600;
  color: var(--pi-text-color-1);
  display: flex;
  border-bottom: 2px solid var(--pits-menu-border);
}

.pi-editassetdialog-body {
  padding: 0px 20px;
  overflow-y: hidden;
  height: 100%;
}

.pi-editassetdialog-variants {
  display: flex;
  height: calc(100% - 96px);
  margin-top: 16px;
}

.pi-editassetdialog-variantslist {
  width: 70%;
  height: 100%;
}

.pi-editassetdialog-variantdetails {
  width: 30%;
  height: 100% !important;
}

.pi-editassetdialog-name {
  display: flex;
  align-items: center;
}
.pi-editassetdialog-name-edit {
  width: 90%;
  margin-right: 16px;
}

.pi-editassetdialog-name-save {
  width: 10%;
  transform: translate(0, 12px);
}
