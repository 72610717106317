.pi-textarea-root {
  position: relative;
}
.pi-textarea-label {
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.pi-textarea-input {
  padding-left: 16px;
  padding-top: 12px;
  color: var(--pi-key-blue);
  box-sizing: border-box;
  border-radius: 28px;
  border: solid 2px var(--pi-key-blue);
  height: 124px;
  width: 100%;
  font-size: 15px;
  resize: none;
  font-family: "Poppins";
  padding-right: 32px;
}

.pi-textarea-input:focus {
  outline: none;
}

.pi-textarea-input::placeholder {
  color: var(--pi-menu-text-color-1);
}

.pi-textarea-info {
  position: absolute;
  right: 5px;
  top: 26px;
}
