.wordspinnertextfield-root {
  height: 48px;
  display: flex;
  position: relative;
}

.wordspinnertextfield-left {
  justify-content: left;
}

.wordspinnertextfield-right {
  justify-content: right;
}
.wordspinnertextfield-center {
  justify-content: center;
}

.wordspinnertextfield-right .wordspinnertextfield {
  text-align: right;
}

.wordspinnertextfield {
  border: none;
  border-bottom: 2px solid var(--pi-game-blue-faded);
  font-family: "Poppins";
  font-size: 24px;
  color: var(--pi-game-blue);
  overflow: visible;
  width: 96px;
  background-color: transparent;
}

.wordspinnertextfield-highlight {
  font-weight: 600;
}

.wordspinnertextfield-error {
  color: var(--pi-error);
}

.wordspinnertextfield::placeholder {
  color: var(--pi-disabled);
}

.wordspinnertextfield:focus {
  outline: none;
  border-bottom: 2px solid var(--pi-game-blue);
}

.wordspinnertextfield-delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--pi-error);

  /* position: absolute;
  top: -32px;
  right: 0px;
  transform: translate(-50%, 50%); */
}

.wordspinnertextfield-toggle {
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translate(-50%, 0);
}

.wordspinnertextfield-toggle-button {
  width: 32px;
  height: 32px;
  border: solid 2px var(--pi-game-blue);
  border-radius: 100%;
  font-size: 24px;
  color: var(--pi-game-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.wordspinnertextfield-toggle-button:hover {
  opacity: 0.75;
}

@media screen and (max-width: 1280px) {
  .wordspinnertextfield {
    font-size: 16px;
  }
}
