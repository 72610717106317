.assetcard-normal-variant {
  --assetcard-bg-color: white;
  --assetcard-img-opacity: 0.5;
  --assetcard-border-color: var(--pits-menu-border);
  --assetcard-lbl-color: var(--pi-yellow);
  --assetcard-lbl-width: 42px;
  --assetcard-title-color: var(--pi-text-color-1);
  --assetcard-subtitle-color: var(--pi-menu-text-color-1-faded);
}

.assetcard-error-variant {
  --assetcard-bg-color: var(--pi-error-faded-1);
  --assetcard-img-opacity: 1;
  --assetcard-border-color: var(--pi-error-faded);
  --assetcard-lbl-color: var(--pi-error);
  --assetcard-lbl-width: 64px;
  --assetcard-title-color: var(--pi-error);
  --assetcard-subtitle-color: var(--pi-error-faded-2);
}

.assetcard-root {
  position: relative;
  width: 90%;
  height: 240px;
  background-color: var(--assetcard-bg-color);
  border-radius: 10px 10px 10px 10px;
  border: 2px solid var(--assetcard-border-color);
  font-family: "Poppins";
  box-shadow: 0px 8px 12px rgba(16, 24, 40, 0.04);
}

.assetcard-unsupported {
}

.assetcard-preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 70%;
  border-radius: 9px 9px 0px 0px;
}

.assetcard-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.5;
}

.assetcard-info {
  border-top: 2px solid var(--assetcard-border-color);
  padding-left: 32px;
  padding-right: 32px;
  height: 30%;
  justify-content: center;
  align-items: center;
}

.assetcard-info-grid {
  height: 100%;
}

.assetcard-assetname {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--assetcard-title-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assetcard-uploadedby {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--assetcard-subtitle-color);
}

.assetcard-version {
  position: absolute;
  top: 16px;
  right: 16px;
  font-family: "Poppins";
  font-weight: 500;
  color: white;
  font-size: 13px;
  line-height: 18px;
  background-color: var(--assetcard-lbl-color);
  width: var(--assetcard-lbl-width);
  height: 22px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assetcard-pdf {
  stroke: var(--pi-pdf-asset);
  stroke-width: 2;
}

.assetcard-video {
  stroke: var(--pi-video-asset);
  stroke-width: 2;
}

.assetcard-audio {
  stroke: var(--pi-audio-asset);
  stroke-width: 2;
}

.assetcard-icon-text {
  position: absolute;
  left: 50%;
  bottom: 47%;
  transform: translate(-50%, 50%);

  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.10000000149011612px;
  text-align: center;
}

.assetcard-pdf-color {
  color: var(--pi-pdf-asset);
}

.assetcard-video-color {
  color: var(--pi-video-asset);
}

.assetcard-audio-color {
  color: var(--pi-audio-asset);
}

.assetcard-unsupported-icon {
  stroke: var(--pi-error);
  stroke-width: 2;
}

.assetcard-loading-icon {
  stroke: var(--pi-interactionpicker-icon);
  stroke-width: 2;
}

.assetcard-onselect:hover {
  cursor: pointer;
  border: 2px solid var(--pi-key-blue);
}

.assetcard-progress {
  padding-top: 10px;
}

.assetcard-hide-image {
  display: none;
}

.assetcard-onselect-wait {
  cursor: progress;
}

.assetcard-onselect-blocked {
  cursor: not-allowed;
}
