.piinteractionassetpreview-root {
  position: relative;
  background-color: var(--pi-game-blue-faded);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.piinteractionassetpreview-select-middle {
  color: var(--pi-game-blue);
  font-size: 64px;
  cursor: pointer;
}

.piinteractionassetpreview-root img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.piinteractionassetpreview-select-bot-right {
  position: absolute;
  right: 16px;
  bottom: 8px;
  color: white;
  background-color: var(--pi-game-blue);
  border-radius: 100%;
  width: 48px;
  height: 48px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.piinteractionassetpreview-select-bot-right:hover {
  opacity: 0.75;
}

.piinteractionassetpreview-video-preview {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
