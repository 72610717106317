.trainingpreviewbar-root {
  width: 100%;
  height: 78px;
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trainingpreviewbar-name {
  display: flex;
  align-items: center;

  width: 33%;
  height: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: var(--pi-text-color-1);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
  margin-left: 20px;
}

.trainingpreviewbar-name span {
  margin-left: 12px;
}

.trainingpreviewbar-editbutton {
  margin-left: auto;
  margin-right: 12px;
  font-size: 32px;
  color: white;
  background-color: var(--pi-key-blue);
  border-radius: 100%;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
}

.trainingpreviewbar-toggle {
  width: 33%;
}

.trainingpreviewbar-toggle-content {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trainingpreviewbar-toggle-content-name {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--pi-text-color-1);
}

.trainingpreviewbar-toggle-offset {
  transform: translateY(-2px);
}
