.pidropdown-root {
    border-radius: 56px;
    border: 2px solid var(--pi-key-blue);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    cursor: pointer;
    position: relative;
    background-color: white;
}

.pidropdown-root-v2 {
    border-radius: 56px;
    border: 2px solid var(--pi-key-blue);
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0px;

    cursor: pointer;
    position: relative;
    background-color: white;

    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
}

.pidropdown-root-v2-style-key-blue {
    border: 2px solid var(--pi-key-blue);
}

.pidropdown-root-v2-style-game-blue {
    border: 2px solid var(--pi-game-blue);
    color: var(--pi-game-blue);
}

.pidropdown-root-disabled {
    border: 2px solid var(--pi-disabled);
    color: var(--pi-disabled);
    cursor: not-allowed;
}

.pidropdown-root-open {
    background-color: var(--pi-main-hover);
}

.pidropdown-text {
    width: calc(100% - 64px);
    padding-left: 4px;
}

.pidropdown-arrow {
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pidropdown-arrow-up {
    transform: rotate(180deg);
    transition: transform 0.15s;
}

.pidropdown-arrow-down {
    transform: rotate(0deg);
    transition: transform 0.15s;
}

.pidropdown-label {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* transform: translate(0, 10px); */
}

.pidropdown-info-button {
    width: 32px;
    margin-right: 4px;
}
