.trainingtimeline-root {
  width: 292px;
  min-width: 292px;

  height: calc(100% - 0px);
  background-color: white;
  border-right: 2px solid var(--pits-menu-border);

  transition: min-width 0.1s, width 0.1s;
}

.trainingtimeline-root-disabled {
  /* visibility: collapse; */
  display: none;
}

.trainingtimeline-content-scroller {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100% - 42px);
  overflow-y: auto;
  /*scrollbar-color: var(--pi-key-blue) var(--pi-scrollbar-bg);*/
  /*scrollbar-width: thin;*/
  /*overflow-x: hidden;*/
 
}

.trainingtimeline-content {
  height: calc(100%);
}

.trainingtimeline-search {
  margin: 16px 16px 0px 16px;
}

/*.trainingtimeline-add-bubble {*/
/*  margin: 0px 16px 0px 16px;*/
/*}*/

.trainingtimeline-add-bubble-content {
  font-weight: 400 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* output */
/*.trainingtimeline-content-scroller::-webkit-scrollbar-thumb {*/
/*  background-color: var(--pi-key-blue);*/
/*  border-radius: 10px;*/
/*}*/
/*.trainingtimeline-content-scroller::-webkit-scrollbar-track {*/
/*  background-color: var(--pi-scrollbar-bg);*/
/*  border-radius: 10px;*/
/*}*/
/*.trainingtimeline-content-scroller::-webkit-scrollbar-corner {*/
/*  background-color: var(--pi-scrollbar-bg);*/
/*  border-radius: 10px;*/
/*}*/
/*.trainingtimeline-content-scroller::-webkit-scrollbar {*/
/*  width: 7px;*/
/*  height: 7px;*/
/*}*/
