.propertiescolumnheader-root {
  height: 48px;
  background-color: #f9f9f9;
  display: flex;
  gap: 8px;
  padding: 0 8px;
  align-items: center;

  color: #1c1b1f;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.propertiescolumnheader-close-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  color: black;
  right: 8px;
  top: 8px;
}
