.new3dtraining-root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  position: relative;

  overflow: hidden;
}
.new3dtraining-logo {
  color: var(--pi-yellow);
  font-size: 48px;
}

.new3dtraining-title {
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.28px;
}

.new3dtraining-title-yellow {
  color: var(--pi-yellow);
}

.new3dtraining-input {
  margin-top: 16px;
  width: 568px;
}

.new3dtraining-submit {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 64px;
  background: rgba(223, 223, 223, 0.15);

  display: flex;
  justify-content: center;
  align-items: center;
}

.new3dtraining-submit-wrapper {
  width: 410px;
}

.new3dtraining-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 176px);
  padding: 0px 8px;
}

.new3dtraining-content::-webkit-scrollbar {
  width: 8px;
}

.new3dtraining-content::-webkit-scrollbar-track {
  background-color: var(--pits-menu-border);
  border-radius: 10px;
}

.new3dtraining-content::-webkit-scrollbar-thumb {
  background-color: var(--pi-key-blue);
  border-radius: 4px;
}

.new3dtraining-content::-webkit-scrollbar-thumb:hover {
  background-color: var(--pi-key-blue);
}
