.audiopopuppreview-body {
  height: 100%;
}

.audiopopuppreview-image-wrapper {
  height: 85%;
}

.audiopopuppreview-audio-wrapper {
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 24px;
  color: var(--pi-audio-asset);
  font-weight: 500;
}

.audiopopuppreview-add-button {
  background-color: var(--pi-game-blue);
  width: 42px;
  height: 42px;
  border-radius: 100%;
  font-size: 24px;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.audiopopuppreview-add-button:hover {
  opacity: 0.75;
}

.audiopopuppreview-file-icon {
  stroke: var(--pi-audio-asset);
  stroke-width: 2;
  width: 32px;
  height: 32px;
}
