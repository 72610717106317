.emotionbubblepreviewbody-root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.emotionbubblepreviewbody-row-1 {
  height: 106px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.emotionbubblepreviewbody-dropdown {
  /* width: 220px;
    min-width: 220px;
    max-width: 220px; */
  width: 33%;
  min-width: 33%;
}
