.piquiztextfield-root {
  width: 100%;
  height: 100%;
  font-size: 24px;
  border: 2px solid var(--pi-game-blue);
  color: var(--pi-game-blue);
  font-family: "Poppins";
  padding-left: 10px;
  border-radius: 5px;
  text-overflow: ellipsis;
}

.piquiztextfield-root::placeholder {
  color: var(--pi-menu-text-color-1-faded);
}

.piquiztextfield-root:focus {
  outline: none;
}
