.clickregionmultiselectquiz-root {
  width: 100%;
  height: 100%;
  position: relative;
}

.clickregionmultiselectquiz-add-button {
  position: absolute;
  width: 200px;
  top: 125px;
  left: 50%;
  transform: translate(-50%, 0);
}
