.timeline3d-root {
  /* position: relative;
  width: 292px;
  height: 100%; */
}

/* .timeline3d-add-button {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
} */

.timeline3d-add-button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
