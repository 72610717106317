.piquizcheckbox-root {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  cursor: pointer;
  border-radius: 5px;
}

.piquizcheckbox-variant-game {
  min-width: 48px;
  height: calc(100% + 2px);
  border: 2px solid var(--pi-game-blue);
  color: var(--pi-game-blue);
  background-color: white;
}

.piquizcheckbox-variant-key {
  border: 2px solid var(--pi-key-blue);
  height: calc(100% + 2px);
  color: var(--pi-key-blue);
  background-color: white;
  font-size: 32px;
  min-width: 32px;
}

@media screen and (max-width: 1280px) {
  .piquizcheckbox-root {
    height: 42px;
    min-width: 42px;
    font-size: 42px;
  }
}

@media screen and (max-width: 1024px) {
  .piquizcheckbox-root {
    height: 40px;
    min-width: 40px;
    font-size: 40px;
  }
}
