.picard-root {
  position: relative;
  background-color: white;
  width: 450px;
  height: 450px;
  border-radius: 10px;
  border: 2px solid var(--pits-menu-border);
  padding-top: 30px;
}

.picard-close-button {
  position: absolute;
  right: 30px;
  top: 34px;
}

@media screen and (max-width: 1280px) {
  .picard-root {
    height: 400px;
    width: 400px;
  }
}
