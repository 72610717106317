.takeactorcard-root {
  height: 36px;
  margin: 8px 0px;
  padding-left: 16px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.takeactorcard-selected {
  color: var(--pi-key-blue);
  background-color: var(--pi-timeline-interaction-selected);
}

.takeactorcard-pfp {
  width: 22px;
  height: 22px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.takeactorcard-pfp img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.takeactorcard-name {
  width: 170px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
