.orderquiz-root {
  display: flex;
  height: 48px;
  gap: 8px;
  top: auto !important;
  left: auto !important;
  position: relative;
}

.orderquiz-delete-btn {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 48px;
  height: 100%;
  cursor: pointer;
  font-size: 32px;
  color: var(--pi-error);
}
.orderquiz-delete-btn:hover {
  color: var(--pi-error-faded-2);
}

.orderquiz-number {
  height: calc(100% + 2px);
  min-width: 48px;
  border: 2px solid var(--pi-game-blue);
  color: var(--pi-game-blue);
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: white;
}

@media screen and (max-width: 1280px) {
  .orderquiz-root {
    height: 35px;
  }
}

@media screen and (max-width: 1024px) {
  .orderquiz-root {
    height: 35px;
  }
}
