.trainingeditor-root {
  height: 100vh;
  width: 100%;
  min-width: 1200px;
  position: relative;
}

.trainingeditor-content {
  position: relative;
  display: flex;
  height: calc(100% - 74px); /* 74px because action bar is 72px high + 2px border */
}
