.pibasicgraphicsquizaddbutton-root {
  height: 100%;
  width: 100%;
  background-color: var(--pi-game-blue-faded);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pibasicgraphicsquizaddbutton-root:hover {
  opacity: 0.75;
}

.pibasicgraphicsquizaddbutton-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pibasicgraphicsquizaddbutton-icon {
  font-size: 48px;
  color: var(--pi-game-blue);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pibasicgraphicsquizaddbutton-text {
  color: var(--pi-game-blue);
  font-size: 16px;
  font-weight: 400;
}
