.page-root {
  width: 100%;
  margin: 32px 0px 0px 0px;
  min-height: 100%;
}

.page-header {
  width: 100%;
}

.page-content {
  margin-top: 32px;
  height: calc(100vh - 170px);
}

.page-content-margin {
  margin-left: 32px;
  margin-right: 32px;
}

.page-content-flex {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 32px;
  align-items: center;
  height: 100%;
}
