.pibasicquizaddbutton-root {
  background-color: var(--pi-game-blue-faded);
  height: 64px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: calc(100%);

  color: var(--pi-game-blue);
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.pibasicquizaddbutton-root:hover {
  opacity: 0.75;
}

.pibasicquizaddbutton-icon {
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
}

.pibasicquizaddbutton-text {
  font-size: 20px;
  margin-left: 8px;
  font-family: "Poppins";
}

@media screen and (max-width: 1280px) {
  .pibasicquizaddbutton-root {
    height: 40px;
  }
  .pibasicquizaddbutton-text {
    font-size: 14px;
    margin-left: 4px;
  }

  .pibasicquizaddbutton-icon {
    font-size: 22px;
  }
}

@media screen and (max-width: 1024px) {
  .pibasicquizaddbutton-root {
    height: 40px;
  }
}
