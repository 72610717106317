.interactioncard-root {
    display: flex;
    margin: 8px 0px 8px 0px;
    align-items: center;
    cursor: pointer;
    background-color: var(--pi-timeline-interaction-bg);
    width: calc(100% - 16px);
    padding-left: 16px;
    padding-right: 8px;
    width: 100%;
}

.interactioncard-selected {
    color: var(--pi-key-blue);
    background-color: var(--pi-timeline-interaction-selected);
}

.interactioncard-selected .interactioncard-name {
    font-weight: 600;
}

.interactioncard-icon {
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.interactioncard-name {
    margin-left: 8px;
    width: 170px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.interactioncard-remove-button {
    margin-left: auto;
}

.interactioncard-root:not(.interactioncard-selected) .interactioncard-remove-button > * {
    color: black;
}

.interactioncard-remove-button > * {
    color: var(--pi-key-blue);
}

.interactioncard-input {
    border: none;
    font-family: Poppins;
    color: var(--pi-key-blue);
    width: 170px;
    padding: 0px;
    background-color: transparent;
}

.interactioncard-input:focus {
    outline: none;
}
