.teleportmarkcard-root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 24px;
  cursor: pointer;
}

.teleportmarkcard-disabled {
  font-weight: 600;
  color: var(--pi-disabled);
  cursor: default;
}

.teleportmarkcard-root:not(.teleportmarkcard-selected):not(
    .teleportmarkcard-disabled
  ) {
  font-weight: 400;
  color: black;
}

.teleportmarkcard-selected {
  font-weight: 600;
  color: var(--pi-key-blue);
  background-color: var(--pi-timeline-interaction-selected);
}

.teleportmarkercard-name {
  margin-left: 8px;
}
