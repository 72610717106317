.connectquizbody-root {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  position: relative;
}

.connectquizbody-root-row {
  flex-direction: column;
}

.connectquizbody-col {
  display: flex;
  flex-direction: column;
  gap: 10%;
  justify-content: center;
}

.connectquizbody-col-a {
  height: 100%;
  width: 100%;
  align-items: end;
}

.connectquizbody-col-b {
  height: 100%;
  width: 100%;
  align-items: start;
}

.connectquizbody-row {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.connectquizbody-row-a {
  height: 40%;
}
.connectquizbody-row-b {
  height: 60%;
}

.connectquizbody-add-button {
  margin: 0 32px;
  background-color: white;
  border-radius: 10px;
  height: 64px;
  cursor: pointer;
  width: calc(40%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--pi-game-blue);
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  bottom: 16px;
  gap: 16px;
}

.connectquizbody-add-button-a {
  width: calc(100% - 32px);
  margin-right: 32px;
}

.connectquizbody-add-button-b {
  width: calc(100% - 32px);
  margin-left: 32px;
}

.connectquizbody-add-button:hover {
  opacity: 0.75;
}

.connectquizbody-add-answer-gfx {
  width: 100%;
  height: 87%;
  top: 5%;
  position: relative;
}

@media screen and (max-width: 1280px) {
  .connectquizbody-add-button {
    height: 32px;
    font-size: 18px;
  }
  .connectquizbody-col {
    gap: 7%;
  }

  .connectquizbody-add-answer-gfx {
    top: 7.5%;
  }
}

@media screen and (max-width: 1024px) {
  .connectquizbody-col {
    gap: 6%;
  }
}
