.picontenteditable-root {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Poppins";
  color: var(--pi-game-blue);
  font-size: 20px;

  overflow: auto;
  min-width: 10px;
}

.picontenteditable-root span {
  background-color: red;
}

.picontenteditable-root:focus {
  outline: none;
}
