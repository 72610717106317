.lisamessagepreview-root {
  height: 100%;
  width: 100%;
}

.lisamessagepreview-position {
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 16px;
  height: 250px;
}

.lisamessagepreview-text-wrapper {
  width: 85%;
  margin: 32px 0px 32px 64px;
  border-radius: 5px;
  display: flex;
}

.lisamessagepreview-text {
  background-color: white;
  width: 100%;
  padding: 8px 8px 8px 8px;
  border-radius: 5px;
}

.lisamessagepreview-text textarea {
  resize: none;
  width: 100%;
  height: 100%;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 50px;
  background-color: transparent;
  color: var(--pi-game-blue);

  border: none;
}

.lisamessagepreview-text textarea:focus {
  outline: none;
}

.lisamessagepreview-arrow {
  margin-top: auto;
  margin-bottom: auto;
}

.lisamessagepreview-img {
  width: 15%;
}
.lisamessagepreview-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
