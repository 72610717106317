.actorpreseteditor-root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100% - 48px);
  overflow-y: auto;
}

.actorpreseteditor-content {
  margin: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.actorpreseteditor-locked {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 200px;

  font-size: 32px;
  color: var(--pi-disabled);
}
