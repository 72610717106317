.pipopupwindowpreview-root {
    position: absolute;
    top: 50%;
    left: 50%;

    background-color: white;
    border-radius: 5px;

    padding: 16px 32px;
    transform: translate(-50%, -50%);

    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
}

.pipopupwindowpreview-root-no-bottom {
  position: absolute;
  top: 50%;
  left: 50%;

  background-color: white;
  border-radius: 10px;

  padding: 16px 32px;
  transform: translate(-50%, -50%);

  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
  border-bottom: 16px solid transparent;
}

.pipopupwindowpreview-size-large {
    width: 90%;
    height: 90%;
}

.pipopupwindowpreview-size-small {
    width: 500px;
    height: 250px;
}

.pipopupwindowpreview-title {
    height: 60px;
}

.pipopupwindowpreview-body-normal {
    width: 100%;
    height: calc(100% - 128px);
}

.pipopupwindowpreview-body-extended {
    width: 100%;
    margin-top: 20px;
    height: calc(100% - 88px);
}

.pipopupwindowpreview-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
}

@media screen and (max-width: 1280px) {
    .pipopupwindowpreview-footer {
        margin-top: 24px;
        height: 38px;
    }

    .pipopupwindowpreview-size-small {
        width: 400px;
        height: 200px;
    }
}

@media screen and (max-width: 1024px) {
    .pipopupwindowpreview-footer {
        margin-top: 24px;
        height: 38px;
    }
}
