.quizpromptpreview-root {
    position: absolute;
    background-color: white;
    width: 40%;
    height: 64px;
    left: 50%;
    top: 32px;
    transform: translate(-50%, 0);
    padding: 16px 24px;
    border-radius: 10px;
    border-bottom: 8px solid var(--pi-game-blue);
    align-items: center;
    display: flex;
}
