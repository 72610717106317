.interactionpropertiescontent-root {
  width: 100%;
}

.interactionpropertiescontent-content {
}

.interactionpropertiescontent-header {
  height: 48px;
  background-color: #f9f9f9;
  display: flex;
  gap: 8px;
  padding: 0 8px;
  align-items: center;

  color: #1c1b1f;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
