.gamewindow-root {
  height: 100%;
  background-color: var(--pi-game-blue-faded);

  overflow: hidden !important;
}

.gamewindow-root iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  overflow: hidden !important;
  z-index: 1000 !important;
}
