.translationprogressbartooltip-row {
  text-align: left;
  display: flex;
  justify-content: start;
  align-items: center;
}

.translationprogressbartooltip-dot-blue {
  background-color: var(--pi-key-blue);
  border-radius: 100%;
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

.translationprogressbartooltip-dot-yellow {
  background-color: var(--pi-key-yellow);
  border-radius: 100%;
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

.translationprogressbartooltip-dot-lightblue {
  background-color: var(--pi-menu-background);
  border-radius: 100%;
  width: 8px;
  height: 8px;
  margin-right: 8px;
}

.translationprogressbartooltip-percentage {
  width: 40px;
}
