.piplaybuttonpreview-root {
  width: 25%;
  height: auto;
  background-color: var(--pi-game-orange);
  margin: auto;
  border-radius: 10px;
  color: white;
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
