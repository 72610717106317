.onboardingbutton {
  bottom: 12px;
  right: 12px;
  cursor: pointer;
  color: var(--pi-key-blue);
}

.onboardingbutton-fixed {
  position: fixed;
}

.onboardingbutton-absolute {
  position: absolute;
}
