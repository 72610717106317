.expanded {
  width: var(--pits-menu-width-expanded);
  min-width: var(--pits-menu-width-expanded);
  transition: min-width 0.1s, width 0.1s;
}

.collapsed {
  width: var(--pits-menu-width-collapsed);
  min-width: var(--pits-menu-width-collapsed);
  transition: width 0.1s, max-width 0.1s;
}

.pimenu-root {
  --menu-border-thickness: 2px;
  float: left;
  height: 100vh;
  border-right: solid var(--menu-border-thickness) var(--pits-menu-border);
  display: flex;
  flex-direction: column;
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
}

.pimenu-logo {
  display: flex;
  justify-content: center;
  height: 212px;
  border-bottom: solid var(--menu-border-thickness) var(--pits-menu-border);
}

.pimenu-logo-small {
  display: none;
  justify-content: center;
  height: 212px;
  border-bottom: solid var(--menu-border-thickness) var(--pits-menu-border);
}

.pimenu-logo-small img {
  flex-grow: 1;
  margin: 10%;
  object-fit: contain;
}

.pimenu-logo img {
  flex-grow: 1;
  margin: 10%;
  object-fit: contain;
}

.pimenu-button-container {
  display: block;
  flex-grow: 1;
  padding: 12px;
  overflow-y: none;
}

.pimenu-button-container > * {
  width: 100%;
}

.pimenu-bottom {
  height: 160px;
  border-top: solid var(--menu-border-thickness) var(--pits-menu-border);
  position: relative;
  min-height: 160px;
}

.pimenu-bottom-content {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  vertical-align: middle;
  margin: 32px 0;
  position: absolute;
  bottom: 0;
  font-size: 16px;
  font-weight: 800;
  width: 100%;
  padding-left: 10px;
}

.pimenu-bottom-content-expanded {
  padding-bottom: 20px;
}

.pimenu-bottom-content-element-icon {
  width: 32px;
}

.pimenu-bottom-content-element {
  display: flex;
  margin: 0px 8px;
  font-family: "inter";
  line-height: 24px;
  flex-grow: 1;
}

.pimenu-username {
  margin-left: 8px;
}

.pimenu-bottom-collapse-button {
  /* margin-right: 24px;
  margin-left: 0px; */
}

.pimenu-bottom-collapse-button-expanded {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, 10px);
}

.hide-element {
  display: none;
}

@media screen and (max-width: 1280px) {
  .pimenu-logo {
    display: none;
  }

  .expanded {
    width: var(--pits-menu-width-collapsed);
    min-width: var(--pits-menu-width-collapsed);
    transition: min-width 0.1s, width 0.1s;
  }

  .collapsed {
    width: var(--pits-menu-width-collapsed);
    min-width: var(--pits-menu-width-collapsed);
    transition: width 0.1s, max-width 0.1s;
  }

  .pimenu-bottom-collapse-button {
    display: none;
  }

  .pimenu-logo-small {
    display: flex;
    height: 100px;
  }
}
