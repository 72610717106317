.markerpreview-root {
  position: absolute;
  --image-width: 100%;
  --image-height: 100%;
  --image-x: 0px;
  --image-y: 0px;

  left: var(--image-x);
  top: var(--image-y);
  width: var(--image-width);
  height: var(--image-height);
}

.markerpreview-marker {
  position: absolute;
  width: 64px;
  height: 64px;
  background-color: var(--pi-key-blue);
  border-radius: 100%;
  --marker-x: 50%;
  --marker-y: 50%;

  left: var(--marker-x);
  top: var(--marker-y);

  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 32px;
  transform: translate(-32px, -32px);
}

.markerpreview-dragging {
  cursor: grabbing;
}

.markerpreview-root.draggable {
  background-color: red;
}
