.pibasicquizaddbuttonsmall-root {
  height: 55px;
  display: flex;
  position: relative;
  width: calc(80%/3);
  background-color: var(--pi-game-blue-faded);
  border:none;
  border-bottom: 2px dashed var(--pi-game-blue);
  justify-content: center;
  align-items: center;

  color: var(--pi-game-blue);
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.pibasicquizaddbuttonsmall-root:hover {
  opacity: 0.75;
}

.pibasicquizaddbuttonsmall-icon {
  width: 32px;
  height: 32px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pi-game-blue);
  color: white;
  border-radius: 50%;
}

.pibasicquizaddbuttonsmall-text {
  font-size: 20px;
  margin-left: 8px;
  font-family: "Poppins";
}

@media screen and (max-width: 1280px) {
  .pibasicquizaddbuttonsmall-root {
    height: 40px;
  }
  .pibasicquizaddbuttonsmall-text {
    font-size: 14px;
    margin-left: 4px;
  }

  .pibasicquizaddbuttonsmall-icon {
    font-size: 8px;
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 1024px) {
  .pibasicquizaddbutton-root {
    height: 40px;
  }
}
