.pieditmenu-root {
    display: flex;
    background-color: var(--pi-game-blue);
    border-radius: 25px;
    height: 32px;
    gap: 4px;
    padding: 2px 4px;
    box-shadow: 4px 4px 12px rgba(16, 24, 40, 0.3);
}

.pieditmenu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    cursor: pointer;
    width: 32px;
    height: 28px;
    border-radius: 15px;
    padding: 2px 0;
    margin: auto;
}

.pieditmenu-button-highlighted {
    background-color: #55839f;
}

.pieditmenu-button:hover {
    background-color: #55839f;
    opacity: 0.75;
}
