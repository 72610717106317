.pi-accountbutton-root {
  display: flex;
  gap: 12px;
  text-align: left;
  border-radius: 10%;
  margin-left: 4px;
  background-color: transparent;
  border: none;
  height: 48px;
  padding-top: 12px;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 60%;

  text-overflow: ellipsis !important;
}

.pi-accountbutton-root:hover {
  background-color: var(--pi-main-hover);
  cursor: pointer;
}

.pi-accountbutton-text {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.pi-accountbutton-username {
  font-weight: 800;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.pi-accountbutton-organizationName {
  font-weight: 400;
  font-size: 10px;
}
