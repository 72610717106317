.wordquizbody-root {
  height: calc(100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 32px;
}

.wordquizbody-background {
  height: 70%;
  width: 100%;
  background-color: var(--pi-game-blue-faded);
  border-radius: 20px;
  padding-top: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wordquizbody-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 32px;
  margin: 32px;
}
.wordquizbody-wrong-answers {
  display: flex;
  width: calc(100% - 64px);
  height: 30%;

  margin: 0 32px;
  justify-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

.wordquizbody-add-wrong-answer {
  position: relative;
  bottom: 0px;
  width: 100%;
  margin: 0 5%;
}

.wordquiz-edit-button {
  font-size: 22px !important;
  color: var(--pi-game-blue) !important;
  transform: translate(0, -3px);
}

.wordquiz-placeholder {
  color: rgba(0, 0, 0, 0.2);
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
}
@media screen and (max-width: 1280px) {
  .wordquizbody-root {
    margin-top: 0px;
    gap: 32px;
  }

  .wordquizbody-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    row-gap: 32px;
  }
}
