.take3Dcard-root {
  margin: 8px 0px;
  border-radius: 5px;
  box-shadow: 0px 8px 12px rgba(16, 24, 40, 0.04);
  background-color: white;
  user-select: none;
}

.take3Dcard-not-selected {
  border: 2px solid var(--pits-menu-border);
}

.take3Dcard-selected {
  border: 2px solid var(--pi-key-blue);
}
